.logoNavbar {
  background-color: rgba(238, 238, 238, 0.835);
}

.logo {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  min-width: 100px;
  height: auto;
}

#root {
  text-align: center;
}


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
.custom-font {
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 18px;
  
}

#txtblanco {
  margin-left: 20px;
  margin-right: 20px;
  color:white;
}


#txtblanco:hover {
  color:#2699d4;
}


.collage-section {
  align-items: center; 
  height: 250px; 
  white-space: nowrap;
  margin-bottom: 0px;
  padding-bottom: 40px;
  padding-top: 60px
}

@media (max-width: 700px) {
  .collage-section {
    height: 150px; 
    padding-top: 25px;
    /* padding-bottom: 20px; */
    /* margin-bottom: 0px; */
  }
}
@media (max-width: 700px) {
.slide img {
  width: 165%!important;
}
}

.collage-image img {
  width: 100%; 
  max-width: 100%; 
  height: auto; 
  min-height: 100px; 
}


.splide__pagination{
  display: none;
}

#splide01 {
  height: 100%;
}

.collage-section img {
  height: 30%; 
  max-width: none;
  margin-right: 60px; 
  margin-left: 60px;
}

@media (max-width: 700px) {
  .collage-section img {
    height: 23%;
    /* margin-right: 40px; 
    margin-left: 40px; */
  }
}


@media only screen and (max-width: 1500px) {
  .collage-section img {
    margin-right: 30px; 
    margin-left: 30px
 }
 }





.navbar-toggler {
margin-left: 10px;
}

/* carousel */

.carousel-status {
  visibility: hidden;
}


/* Catalogue */



.white {
  color: white;
}


.Catalogue {
  text-align: center;
  margin-top: 2rem;
  background-color: #222429;
  /* padding-top:20px; */
}

/* ... ... */

.imagen-con-titulo {
  display: inline-block;
  margin: 30px;
  vertical-align: top;
  text-align: center;
  max-width: 250px;
  background-color: white;
  margin-top: 50px;
  border: 3px solid rgba(238, 238, 238, 0.835);
}

.titulo-catalogue a {
  text-decoration: none;
}



/* ...  ... */



.imagen-con-titulo img {
  max-width: 100%;
  height: auto;
  /* padding-bottom: 60px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 80px; */

}

.imagen-con-titulo h2 {
  /* margin-top: 20px; */
  font-size: 16px;
  background-color: rgba(238, 238, 238);
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 0px;
  font-weight: 700;
  text-transform: uppercase;
  
}

.imagen-con-titulo h2 a {
  color: #222429; 
}

.imagen-con-titulo h2 a:hover {
  /* color: #ff9900; */
  color:#2699d4;
}


.Catalogue h1, p {
color:white;
max-width: 850px;
margin-right: auto;
margin-left: auto;
padding-left:20px;
padding-right:20px;
}

.Catalogue p{
  color:white;
}


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
.Catalogue h1 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 30px;
  padding-top: 50px;
}


.Catalogue {
  padding-bottom: 80px;
}




/* STOCKIST */

.fila {
  max-width: 800px;
}

.contenedor-imagenes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px; 
  margin: 0 auto; 
}

.imagenStockist {
  max-width: 100%; 
  height: auto; 
  margin: 10px; 
}


@media (max-width: 1200px) {
  .contenedor-imagenes {
    padding: 10px; 
  }
}


.imagenStockist {
  height: 130px;
}


.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;   
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}



.image-row {
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
  margin-bottom: 10px;
}

.small-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px; 
}

.small-image img {
  max-width: 100%;
  height: auto;
}

.large-image img {
  max-width: 100%;
  height: auto;
}


.stockist p{
  color: rgba(0, 0, 0, 0.800);
}

.stockist h1, p{
  color:rgb(19, 19, 19);
  max-width: 850px;
  margin-right: auto;
  margin-left: auto;
  padding-left:20px;
  padding-right:20px;
  }
  
  
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
  .stockist h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 30px;
    padding-top: 50px;
  }



  /* nuevo */

  .imagenes-contenedor {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centra las imágenes en el contenedor */
    gap: 20px; /* Espacio entre las columnas */
    margin: 0 auto;
    max-width: 1200px;
  }
  
  .imagen-con-titulo {
    width: calc(33.33% - 20px); /* Divide el espacio en 3 columnas con un margen entre ellas */
    text-align: center;
  }
  
  @media (max-width: 1200px) {
    .imagen-con-titulo {
      width: calc(50% - 20px); /* En pantallas medianas (como tabletas) usar 2 columnas */
    }
  }
  
  @media (max-width: 768px) {
    .imagen-con-titulo {
      width: 100%; /* En pantallas pequeñas (móviles) usar una sola columna */
    }
  }
  

  /* hasta aca */



  /* ABOUT US */

  .aboutUs {
    background-color: rgba(238, 238, 238) ;
    margin-top: 50px;

    padding-top: 100px;
    padding-bottom: 100px;
  }

  @media (max-width: 865px) {
    .aboutUs {
      margin-top: 0px;
    }
  }

  
  .aboutUs p{
    max-width: 1200px;
    color: rgba(0, 0, 0, 0.800);
    font-size: 23px;
    font-weight: 600;
  }


  .aboutUs h1{
      max-width: 850px;
      margin-right: auto;
      margin-left: auto;
      padding-left:20px;
      padding-right:20px;
      }
  
      @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
  .aboutUs h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 30px;
  }



  /* CONTACT US */

  .footer h1{
    color:white;
    max-width: 850px;
    margin-right: auto;
    margin-left: auto;
    padding-left:20px;
    padding-right:20px;
    padding-top: 50px
    }

    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
.footer h1 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 30px;
}

.footer p{
  color: white;
}

.footer {
  background-color: #222429;
}




/* Footer - ContactUs */

.contactInfo {
  color:white;
  text-align: left;
}


@media (max-width: 800px) {
  .links-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .link-item {
    margin: 5px;
  }
}

.splide__arrows {
  display: none;
}

ul.splide__pagination  {
  display: none;
}

.link-item {
  margin: 0 20px; 
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  color:white;
}

.link-item a:hover {
  color:#2699d4;
}

.linkFooter {
  color: white;
}

.logoFooter {
margin-bottom: 20px;
}


.contactInfo{
  float: right;
  margin-right: 50px;
}


@media only screen and (max-width: 991px) {
  .contactInfo {
float:none;
margin-right: 0px;
 }
 }



 @media only screen and (max-width: 991px) {
  .contactInfo {
    text-align: center;
  }

  .contactInfo .logo,
  .contactInfo .info-email,
  .contactInfo .info-call {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .contactInfo .info-email,
  .contactInfo .info-call {
    display: inline-block;
    width: 49%;
  }

  .contactInfo .info-email h4,
  .contactInfo .info-email h7,
  .contactInfo .info-call h4,
  .contactInfo .info-call h6 {
    margin: 0;
  }
}


@media only screen and (max-width: 466px) {
  .contactInfo .logo,
  .contactInfo .info-email,
  .contactInfo .info-call {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .contactInfo .info-email,
  .contactInfo .info-call {
    display: block;
    width: 100%;
  }

  .contactInfo .info-email h4,
  .contactInfo .info-email h7,
  .contactInfo .info-call h4,
  .contactInfo .info-call h6 {
    text-align: center;
    margin: 0;
  }
}

@media only screen and (max-width: 466px) {
  .contactInfo {
/* text-align: center; */
float:none;
margin-right: 0px;
 }
 }


#links-footer {
  margin-right: auto;
  margin-left: auto;
}



/* PARTNERS */

.imagen-con-titulo-custom img {
  max-width: 150px;
  min-width: 100px;
  height: auto;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 1;
}

.imagen-con-titulo-custom img:hover {
  transform: scale(1.2); 
  opacity: 0.9; 
}

.custom-images-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1px; 

}

@media (min-width: 1200px) {
  .container-custom-images {
    width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .custom-images-container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}


.container-custom-images {
  margin-top: 40px;
}

.titlePartner {
margin-top:10px;
}



#partner {
background-color: white;
}


@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
.partner h1 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 30px;
  padding-top: 50px;
}

.partner {
  margin-bottom: 80px;
}